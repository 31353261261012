<template>
  <div>
    <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- articolo -->
      <template #cell(articolo)="data">
        <span class="font-weight-bolder">
            {{ data.item.descrizione_articolo }}
        </span><br />
        <span class="font-small-2 text-muted">
            codice art.: {{ data.item.codice_articolo }}
        </span>
      </template>

      <!-- prezzo_cadauno -->
      <template #cell(prezzo_cadauno)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.prezzo }} €</span>
        </div>
      </template>

      <!-- subtotale_riga -->
      <template #cell(subtotale_riga)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.subtotale }} €</span>
        </div>
      </template>

      <!-- blocco_iva -->
      <template #cell(blocco_iva)="data">
        {{ data.item.iva+'%' }}
      </template>
      
    </b-table>
  </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    VueGoodTable,
  },
  data() {
    return {
      tipo_doc: 'oc',
      isLoading: true,
      emptystate: true,
      
      fields: [
        { key: 'articolo', label: 'Articolo' },
        { key: 'qt', label: 'Quantità' },
        { key: 'um', label: 'UM' },
        { key: 'prezzo_cadauno', label: 'Prezzo cadauno' },
        { key: 'subtotale_riga', label: 'Subtotale' },
        { key: 'blocco_iva', label: 'Iva' },
        { key: 'sconto', label: 'Sconto' },
      ],
      tableData: {
        type: Array,
        default: () => [],
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.$http.get('v2/documenti/lista/'+this.userData.id_azienda+"/"+this.tipo_doc)
      .then(response => {
        this.data = response.data

        if(response.data.articoli_carrello == 0){
          //carrello vuoto
          this.carrello_vuoto = true;
        } else {
          //almeno un articolo c'è => procediamo con l'ordine
          this.carrello_vuoto = false;
          
        }

        console.log(response.data);

      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>